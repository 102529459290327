<template>
  <div class="modal-card">
    <div class="modal-content">
      <div class="box">
        <div class="columns is-mobile">
          <div class="column">
            <p class="subtitle has-text-primary">Advert text</p>
          </div>
          <div class="column is-narrow">
            <a class="has-text-danger" @click="$modal.close">
              Close
            </a>
          </div>
        </div>
        <div class="content" v-html="data" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdTextModal',
  props: {
    data: {
      type: String,
      required: true
    }
  }
}
</script>
